import React from 'react';
import './App.css';
import Buttons from '../src/components/Buttons';
import Cards from '../src/components/Cards';
import Dropdowns from '../src/components/Dropdowns';

function App() {
  return (
    <div className="App">
      <h1> Welcome Oskar </h1>
      <Buttons/>
      <Cards/>
      <Dropdowns/>
    </div>
  );
}

export default App;